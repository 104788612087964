import { IoArrowBackCircle, IoPencil, IoWarningSharp  } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { linkAfiliado, nomePlataforma, valorDeposito } from "../../const";

export default function Cadastro07() {

  const navigate = useNavigate();

  function toHome() {
    navigate('/07');
  }

  return (
    <div className="min-w-full min-h-screen bg-red-700">
      <div className="h-12 bg-black text-white font-medium text-base flex justify-between px-4 items-center">
        <div className="flex items-center" onClick={toHome}>
          <IoArrowBackCircle size={25} color="yellow" />
          <p className="pl-2">Voltar ao início</p>
        </div>
        <div className="flex items-center">
          <IoPencil size={25} color="yellow" />
          <p className="pl-2">Cadastro</p>
        </div>
      </div>
      <div className="justify-center flex text-white flex-col items-center">
        <h1 className="font-bold text-2xl mt-7">CADASTRO</h1>
        <div className="items-center justify-center flex max-w-[90%] mt-4 mb-10">
          <div className="text-center leading-3">
            <span className="font-bold text-yellow-400 text-base">#DICA: </span>
            <span className="text-base font-medium">DEPOSITANDO A PARTIR DE </span>
            <span className="text-base font-bold tracking-tighter text-yellow-400">R${valorDeposito} </span>
            <span className="text-base font-medium">VOCÊ GANHA  </span>
            <span className="font-bold text-yellow-400 text-base">O DOBRO </span>
            <span>NA HORA</span>
          </div>
        </div>
        <iframe src={linkAfiliado} className="border border-yellow-400 rounded-md h-[650px] w-[90%] mb-14" />
        <IoWarningSharp size={30} color="yellow"/>
        <p className="mt-8 text-sm font-light w-[80%] text-center mb-24">Não possuímos nenhum tipo de ligação com o site {nomePlataforma}. Apenas facilitamos o seu processo de depósito e saque através do aplicativo.</p>
      </div>
    </div>
  );
}
