import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
 
//PAGES

//HOMES
import Home from '../pages/home/';
import Home1 from '../pages/home01';
import Home2 from '../pages/home02';
import Home3 from '../pages/home03';
import Home4 from '../pages/home04';
import Home5 from '../pages/home05';
import Home6 from '../pages/home06';
import Home7 from '../pages/home07';
import Home8 from '../pages/home08';
import Home9 from '../pages/home09';

//CADASTRO
import Cadastro00 from '../pages/cadastro';
import Cadastro01 from '../pages/cadastro01';
import Cadastro02 from '../pages/cadastro02';
import Cadastro03 from '../pages/cadastro03';
import Cadastro04 from '../pages/cadastro04';
import Cadastro05 from '../pages/cadastro05';
import Cadastro06 from '../pages/cadastro06';
import Cadastro07 from '../pages/cadastro07';
import Cadastro08 from '../pages/cadastro08';
import Cadastro09 from '../pages/cadastro09';

//HACK
import Hack00 from '../pages/hack';
import Hack01 from '../pages/hack01';
import Hack02 from '../pages/hack02';
import Hack03 from '../pages/hack03';
import Hack04 from '../pages/hack04';
import Hack05 from '../pages/hack05';
import Hack06 from '../pages/hack06';
import Hack07 from '../pages/hack07';
import Hack08 from '../pages/hack08';
import Hack09 from '../pages/hack09';


//TERMOS E POLITICAS
import Termos from '../pages/termos';
import Politica from '../pages/politica';

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="/00" element={<Home />} />
        <Route path="/01" element={<Home1 />} />
        <Route path="/02" element={<Home2 />} />
        <Route path="/03" element={<Home3 />} />
        <Route path="/04" element={<Home4 />} />
        <Route path="/05" element={<Home5 />} />
        <Route path="/06" element={<Home6 />} />
        <Route path="/07" element={<Home7 />} />
        <Route path="/08" element={<Home8 />} />
        <Route path="/09" element={<Home9 />} />

        <Route path="/cadastro00" element={<Cadastro00 />} />
        <Route path="/cadastro01" element={<Cadastro01 />} />
        <Route path="/cadastro02" element={<Cadastro02 />} />
        <Route path="/cadastro03" element={<Cadastro03 />} />
        <Route path="/cadastro04" element={<Cadastro04 />} />
        <Route path="/cadastro05" element={<Cadastro05 />} />
        <Route path="/cadastro06" element={<Cadastro06 />} />
        <Route path="/cadastro07" element={<Cadastro07 />} />
        <Route path="/cadastro08" element={<Cadastro08 />} />
        <Route path="/cadastro09" element={<Cadastro09 />} />

        <Route path="/hack00" element={<Hack00 />} />
        <Route path="/hack01" element={<Hack01 />} />
        <Route path="/hack02" element={<Hack02 />} />
        <Route path="/hack03" element={<Hack03 />} />
        <Route path="/hack04" element={<Hack04 />} />
        <Route path="/hack05" element={<Hack05 />} />
        <Route path="/hack06" element={<Hack06 />} />
        <Route path="/hack07" element={<Hack07 />} />
        <Route path="/hack08" element={<Hack08 />} />
        <Route path="/hack09" element={<Hack09 />} />

        <Route path="/termos" element={<Termos />} />
        <Route path="/politica" element={<Politica />} />
      </Routes>
    </Router>
  );
}

export default Rotas;