import React, { useState, useEffect } from 'react';

const Modal = ({ onClose }) => {

  const sendDataToSheet = async (data) => {
    try {
      const response = await fetch('https://hook.eu2.make.com/lbp3g1fo65k2ss08phmi875lyaj4vvrd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Não foi possível enviar os dados para o Google Sheets');
      }
      console.log('Dados enviados com sucesso!', nome, whatsapp);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
  };

  function processData() {
    sendDataToSheet({
      nome: nome,
      email: whatsapp,
    });
  }

  const [nome, setNome] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    processData()
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  const handleScroll = () => {
    console.log('Scroll detectado!');
    onClose()
  };

  useEffect(() => {
    setTimeout(() => {

      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);

    }, 1000);
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-65 flex justify-center items-center p-5" onClick={handleBackdropClick}>
      <div className="bg-slate-900 p-5 rounded-lg shadow-lg border-red-600 border">
        <h2 className="text-xl font-bold mb-2 text-white">Participe do sorteio de R$500 reais toda semana!</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="nome" className="block text-gray-100 text-sm font-bold mb-2">Nome:</label>
            <input
              type="text"
              id="nome"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="whatsapp" className="block text-gray-100 text-sm font-bold mb-2">WhatsApp: (DDD)</label>
            <input
              type="tel"
              id="whatsapp"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              required
              pattern="[0-9]{2}[0-9]{4,5}[0-9]{4}"
              title="Digite um número de WhatsApp válido. Ex: 11987654321"
            />
          </div>
          <div className='items-center flex justify-between'>
            <button
              className="font-bold py-2 px-4 border rounded bg-red-600 text-white"
              onClick={onClose}
            >
              Fechar
            </button>
            <button
              type="submit"
              className="bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Participar
            </button>
          </div>
        </form>

      </div>
    </div>
  );
};

export default Modal;