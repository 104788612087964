import { IoWarningSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { linkAfiliado, nomePlataforma } from "../../const";

export default function Hack09() {

  const navigate = useNavigate();

  const [entry,setEntry] = useState('-');
  const [turbo,setTurbo] = useState('-');
  const [vality,setVality] = useState('-');
  const [timer, setTimer] = useState(60);

  const [isDisabled, setIsDisabled] = useState(false);

  function generateSignal() {
    function randomBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  
    setEntry(randomBetween(5, 10)+"X");
  
    setTurbo(randomBetween(5, 13)+"X");
  
    const now = new Date();
    now.setMinutes(now.getMinutes() + 2);
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0'); 
    setVality(hours+":"+minutes);

    setIsDisabled(true);
    setTimer(60);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsDisabled(false);
      clearInterval(intervalId); 
    }, 60000);
    
  }

  return (
    <div className="min-w-[100%] min-h-screen bg-red-700 flex flex-col items-center">
      <div className="bg-tigrin3 w-[100%] lg:w-[50%] h-[550px] bg-no-repeat bg-contain bg-center mt-8 scale-110">
        <div className="min-w-full items-center flex justify-center flex-col pt-64 text-lg">
          <div className="flex bg-red-600 min-w-[260px] text-white font-bold rounded-md w-[10%] h-14 items-center pl-5">
            <div className="bg-entrada w-12 h-12 bg-no-repeat bg-contain bg-center mr-2" />
            <p>ENTRADA: {entry}</p>
          </div>
          <div className="flex bg-red-600 min-w-[260px] text-white font-bold rounded-md w-[10%] mt-4 h-14 items-center pl-5">
            <div className="bg-turbo w-12 h-10 bg-no-repeat bg-contain bg-center mr-2" />
            <p>TURBO: {turbo}</p>
          </div>
          <div className="flex bg-red-600 min-w-[260px] text-white font-bold rounded-md w-[10%] mt-4 h-14 items-center pl-5">
            <div className="bg-validade w-12 h-10 bg-no-repeat bg-contain bg-center mr-2" />
            <p>VALIDADE: {vality}</p>
          </div>
          <div className="flex bg-red-600 min-w-[260px] text-white font-bold rounded-md w-[10%] mt-8 h-12 items-center">
            <button 
              onClick={() => generateSignal()} 
              disabled={isDisabled} 
              className={`bg-custom-yellow text-black min-w-[100%] min-h-full rounded-md text-base font-bold transition-transform duration-150 hover:scale-105 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isDisabled ? `Aguarde ${timer} segundos ` : 'GERAR NOVO SINAL'}
            </button>
          </div>
        </div>
      </div>
      <div className="bg-black flex flex-col items-center">
        <div className="mt-14 text-zinc-700 max-w-[90%] text-center">
          <p className="mt-0 tracking-tighter">Atenção! Este hack visa ser uma REFERÊNCIA para o seu jogo. De forma alguma garantimos totalidade em acertos ou ganhos expressivos.</p>
          <p className="mt-0 tracking-tighter">Aposte com responsabilidade! Não coloque dinheiro que possa te fazer falta. Jogue por DIVERSÃO. Aposta NÃO É renda extra! CUIDADO! Aposta pode viciar!</p>
        </div>
        <div className="justify-center flex text-white flex-col items-center mt-8">
          <iframe src={linkAfiliado} className="border border-yellow-400 rounded-md h-[650px] w-[90%] mb-8" />
          <IoWarningSharp size={30} color="yellow" />
          <p className="mt-3 text-sm font-light w-[80%] text-center mb-24">Não possuímos nenhum tipo de ligação com o site {nomePlataforma}. Apenas facilitamos o seu processo de depósito e saque através do aplicativo.</p>
        </div>
      </div>
    </div>
  );
}