import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

//IMAGENS
import lead1 from '../../assets/lead.jpeg';
import lead2 from '../../assets/lead1.jpeg';
import lead3 from '../../assets/lead2.jpeg';

export default function Home6() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  //* USE EFFECT QUE ABRE O MODAL AO CARREGAR A PÁGINA */
  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  function toCadastro() {
    navigate('/cadastro06');
  }

  function toTermos() {
    navigate('/termos');
  }

  function toPolitica() {
    navigate('/politica');
  }

  function toHack() {
    navigate('/hack06');
  }

  return (
    <div className='min-w-full min-h-screen bg-custom-gradient text-white'>
      <div className="flex-grow flex justify-center flex-col items-center">
        <div className="bg-tigrin4 lg:w-[60%] min-w-[93%] bg-contain min-h-[280px] bg-no-repeat mt-3 bg-center"></div>
        <div className="lg:w-[60%] w-[93%] align-middle bg-red-700 flex flex-col items-center rounded-t-md">
          <button className="bg-custom-yellow text-black max-w-[80%] mt-[-10px] rounded-md text-lg font-semibold py-4 px-10 leading-5 transition-transform duration-150 hover:scale-105" onClick={toCadastro}>CRIAR CONTA E RESGATAR BÔNUS</button>
          <h1 className="font-bold text-3xl self-start pl-2 mt-3">Hacker do Tigre</h1>
          <p className="max-w-[90%] pl-2 self-start text-[18px] font-medium mt-3 text-justify tracking-tighter">Siga o passo a passo para acessar o Hack validado e aumentar suas chances no jogo do tigre </p>
          <button onClick={toHack} className="bg-custom-yellow text-black min-w-[80%] mt-5 rounded-md text-[16px] font-medium py-2 px-5 mb-5 transition-transform duration-150 hover:scale-105">ACESSE O APLICATIVO HACK</button>
          <div className="self-start px-2 w-full">
            <p className="pb-1">70% das vagas preenchidas</p>
            <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700 mb-5">
              <div
                className="bg-black h-2 rounded-full transition-all duration-1000 ease-out"
                style={{ width: "70%" }}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-[60%] w-[93%] align-middle h-auto bg-red-700 flex flex-col px-3 items-center mb-10 pb-10">
          <div>
            <h1 className="self-start text-3xl mt-6 font-medium text-balance">Veja como é o hacker do Tigre por dentro!</h1>
            <p className="mt-5 text-[18px] pr-5">Receba sinais todos os dias dos horários pagantes do Fortune Tiger</p>
          </div>
          <div className="bg-custom-yellow max-w-[95%] mt-12 h-[auto] p-6 rounded-md">
            <div className="bg-cifrao bg-cover w-12 h-12" />
            <h2 className="text-3xl font-bold text-black mt-3">Estratégias</h2>
            <p className="mt-3 font-medium text-[18px] text-slate-900">Tenha acesso as melhores e mais assertivas estratégias validadas para bancas baixas</p>
          </div>
          <div className="bg-custom-yellow max-w-[95%] mt-12 h-[auto] p-6 rounded-md">
            <div className="bg-cifrao bg-cover w-12 h-12" />
            <h2 className="text-3xl font-bold text-black mt-3">Suporte 24h</h2>
            <p className="mt-3 font-medium text-[18px] text-slate-900">Temos um suporte 100% qualificado pronto para te atender qualquer horário do dia</p>
          </div>
          <div className="bg-slate-900 max-w-[95%]  mt-12 h-[auto] p-4 rounded-md justify-center  flex-col items-center">
            <h2 className="text-3xl font-bold text-grey mt-1">Depoimentos</h2>
            <p className="mt-6 font-light text-[18px] text-gray-50 text-center">Arraste para o lado ou aperte na seta para ver os depoimentos de quem usa!</p>
            <div className="flex items-center bg-blue-500 mt-4">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="bg-zinc-950 w-full md:h-[480px]"
              >
                <SwiperSlide className="flex items-center justify-center h-full" style={{display: 'flex'}}>
                  <img src={lead1} className="md:h-[480px]"></img>
                </SwiperSlide>
                <SwiperSlide className="flex items-center justify-center h-full" style={{display: 'flex'}}>
                  <img src={lead2} className="md:h-[480px]"></img>
                </SwiperSlide>
                <SwiperSlide className="flex items-center justify-center h-full" style={{display: 'flex'}}>
                  <img src={lead3} className="md:h-[480px]"></img>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="bg-tigrin2 bg-no-repeat w-48 h-48 bg-center bg-contain mt-5 animate-pulse" />
          <button onClick={toHack} className="bg-custom-yellow text-black min-w-[60%] mt-[-20px] rounded-md text-xl font-medium py-2 px-5 mb-5 transition-transform duration-150 hover:scale-105">Acesso ao hack</button>
        </div>
      </div>
      <div className="flex-grow flex justify-center flex-col items-center">
        <p className="text-center w-[90%] text-zinc-600 font-medium">Atenção! Este hack visa ser uma REFERÊNCIA para o seu jogo. De forma alguma garantimos totalidade em acertos ou ganhos expressivos. </p>
        <p className="text-center w-[90%] mt-5 text-zinc-600 font-medium">Jogue com responsabilidade! Não coloque dinheiro que possa te fazer falta. Jogue por DIVERSÃO.  </p>


        <p className="text-center w-[90%] mt-14 mb-14 text-white font-bold text-lg">Copyright 2024 – Todos os direitos reservados  </p>
      </div>
      {isModalOpen && <Modal onClose={handleClose} />}
    </div>
  );
}
