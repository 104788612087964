import './index.css'
import Rotas from './routes/router';

function App() {
  return (
    <Rotas />
  );
}

export default App;
