function Termos() {
  return (
    <div className="px-8 flex flex-col bg-slate-200 min-h-screen min-w-full pb-10">
      <h2 className="text-2xl mt-6">1. Termos</h2>
      <p className="mt-5 text-justify">
        Ao acessar ao site AppsLucrativos, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
      </p>
      <h2 className="text-2xl mt-6">2. Uso de Licença</h2>
      <p className="mt-5 text-justify">
        É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site AppsLucrativos , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode:
      </p>
      <div>
        <ol className="list-decimal pl-10 mt-2">
          <li>modificar ou copiar os materiais;</li>
          <li>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
          <li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site AppsLucrativos; </li>
          <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou </li>
          <li>transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.</li>
        </ol>
      </div>
      <p className="mt-5 text-justify">
        Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por AppsLucrativos a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.
      </p>
      <h2 className="text-2xl mt-6">3. Isenção de responsabilidade</h2>
      <div>
        <ol className="list-decimal pl-10 mt-4">
          <li>Os materiais no site da AppsLucrativos são fornecidos 'como estão'. AppsLucrativos não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.</li>
          <li>Além disso, o AppsLucrativos não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.</li>
        </ol>
      </div>
      <h2 className="text-2xl mt-6">4. Limitações</h2>
      <p className="mt-5 text-justify">
        Em nenhum caso o AppsLucrativos ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em AppsLucrativos, mesmo que AppsLucrativos ou um representante autorizado da AppsLucrativos tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.
      </p>
      <h2 className="text-2xl mt-6">5. Precisão dos materiais</h2>
      <p className="mt-5 text-justify">
        Os materiais exibidos no site da AppsLucrativos podem incluir erros técnicos, tipográficos ou fotográficos. AppsLucrativos não garante que qualquer material em seu site seja preciso, completo ou atual. AppsLucrativos pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, AppsLucrativos não se compromete a atualizar os materiais.
      </p>
      <h2 className="text-2xl mt-6">6. Links</h2>
      <p className="mt-5 text-justify">
        O AppsLucrativos não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por AppsLucrativos do site. O uso de qualquer site vinculado é por conta e risco do usuário.
      </p>
      <h2 className="text-2xl mt-6">Modificações</h2>
      <p className="mt-5 text-justify">
        O AppsLucrativos pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.
      </p>
      <h2 className="text-2xl mt-6">Lei aplicável</h2>
      <p className="mt-5 text-justify">
        Estes termos e condições são regidos e interpretados de acordo com as leis do AppsLucrativos e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.</p>
    </div>
  )
}

export default Termos;